@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
h1 {
  @apply text-2xl font-bold mb-4 font-roboto;
}

/* If you want to apply the Roboto font to all text elements */
body, p, div, label, input, button {
  @apply font-roboto;
}
